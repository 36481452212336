<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}} 
                </v-card-title>
                <v-card-text>
                    <!-- ---------------------------------------------------- -->
                    <v-switch v-model="valor" label="Limitación física" value="fisica-2"></v-switch>
                    <v-switch v-model="valor" label="Estado mental alterado" value="mental-3"></v-switch>
                    <v-switch v-model="valor" label="Tratamiento farmacol. que implica riesgo" value="farmaco-2"></v-switch>
                    <v-switch v-model="valor" label="Problema de idoma o socioculturales" value="idioma-2"></v-switch>
                    <v-switch v-model="valor" label="Paciente sin factores de riesgo" value="ninguno-1"></v-switch>
                    <v-text-field
                        v-model="observaciones"
                        filled
                        rounded
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>
                    <v-expand-transition>
                        <div v-if="sumaValoracion>0" class="text-center">
                            <div class="title">Puntaje: <span>{{sumaValoracion}}</span></div>
                            <div  class="font-weight-black" :style="'color: ' + colorValoracion">{{textoValoracion}}</div>
                        </div>
                    </v-expand-transition>
                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :disabled="!sumaValoracion"
                        :loading="loading"
                        @click="agrega()"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela()">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loading:false,
        valor:[],
        observaciones:'',
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        },

        sumaValoracion(){
            if (this.valor.length > 0){
                let suma = 0
                this.valor.forEach(ele =>{
                    suma=suma + parseInt(ele.split('-')[1])
                })
                return suma
            } else {
                return null
            }
        },
        textoValoracion(){
            if (this.sumaValoracion){
                let text = ''
                if (this.sumaValoracion <= 1)
                    text = 'Bajo riesgo'
                if (this.sumaValoracion > 1 && this.sumaValoracion <= 3)
                    text = 'Mediano riesgo'
                if (this.sumaValoracion > 3)
                    text = 'Alto riesgo'
                return text
            } else {
                return null
            }
        },
        colorValoracion(){
            let text = ''
                if (this.sumaValoracion <= 1)
                    text = 'green'
                if (this.sumaValoracion > 1 && this.sumaValoracion <= 3)
                    text = 'orange'
                if (this.sumaValoracion > 3)
                    text = 'red'
            return text
        }
    },
    methods:{
        cancela(){
            this.valor=[]
            this.observaciones=''
            this.dialog = false

        },
        async agrega(){
            //console.log(this.valor)




            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'caidas',
                valor: this.valor.toString(),
                internamiento:this.internamiento,
                observaciones: this.observaciones
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.valor=[]
                    this.observaciones = ''
                    this.loading = false
                    this.dialog = false

                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
        },
    },
}
</script>

<style>

</style>